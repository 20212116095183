import React, { useEffect, useState, useRef } from "react";
import { getRandomColor, createImageFromInitials } from "../Utils";
import Calendar from "../../assets/images/Calendar.svg";
import DatePicker from "react-datepicker";
import { Button } from "react-bootstrap";
import Select from "react-select";
import CommentSection from "./Comment";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "../../assets/images/trash.svg";
import { useDispatch, useSelector } from "react-redux";
import { StepProgressBar } from "../Utils";
import { getTaskDetails, postStrategyFunctions } from "../../store/actions";
import ChevronRight from "../../assets/images/Chevron-right.svg";
import QuillEditor from "../../utils/QuillEditor";
import AddIcon from "../../assets/images/add.svg";
import Alert from "../Alert.js";
import { resetExceptionError } from "../../store/actions/index.js";
import {
  postFolderManagerDelete,
  postLocalFolderManager,
  getLocalFolderManager,
  postLocalFolderManagerFileDelete,
  getXLExecutionPlanDetails,
} from "../../store/actions/index.js";
import { url } from "../../store/sagas/API.js";
import UploadImage from "../../assets/images/icon.svg";
import SheetImage from "../../assets/images/icons8-excel.svg";
import DocImage from "../../assets/images/icons8-microsoft-word.svg";
import PDFImage from "../../assets/images/pdf-svgrepo-com.svg";
import PPTImage from "../../assets/images/icons8-powerpoint.svg";
import TXTImage from "../../assets/images/icons8-text.svg";
import OtherImage from "../../assets/images/icons8-document.svg";
import NoDocImage from "../../assets/images/nodoc.svg";

function TaskSidebar({
  isNewTask,
  selectedTaskMilestoneId,
  breadcrumbs,
  empData,
  statusData,
  priorityData,
  mileStonesData,
  selectedTaskId,
  onClose,
  handleTaskDelete,
  isSubTaskVisible,
  setIsSubTaskVisible,
}) {
  const dispatch = useDispatch();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [task, setTask] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [assignedTo, setAssignedTo] = useState([]);
  const [desc, setDesc] = useState("");
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [milestone, setMilestone] = useState("");
  const [duedate, setDuedate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [comments, setComments] = useState("");
  const [subtasks, setSubtasks] = useState("");
  const [milestoneId, setMilestoneId] = useState("");
  const [collabarators, setCollabarators] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSubTaskSidebarOpen, setIsSubTaskSidebarOpen] = useState(false);
  const [currentTaskId, setCurrentTaskId] = useState("");
  const [subTaskId, setSubTaskId] = useState("");
  const [isNewSubTask, setIsNewSubTask] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [attachment, setAttachment] = useState("");
  const [showTaskUploadFile, setShowTaskUploadFile] = useState(false);
  const [showNewTaskUploadFile, setShowNewTaskUploadFile] = useState(false);
  const [showSubTaskUploadFile, setShowSubTaskUploadFile] = useState(false);
  const [newUploadFileTaskId, setNewUploadFileTaskId] = useState("");
  const [newUploadFieSubTaskId, setNewUploadFileSubTaskId] = useState("");
  // const [AddSubTaskVisible, setAddSubTaskVisible] = useState(false);
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5", "Step 6"];
  const [progress, setProgress] = useState(0);
  const getSingleTaskDetails = useSelector(
    (state) => state.getSingleTaskDetails
  );
  const selectedSubMenuPermission = useSelector(
    (state) => state.selectedSubMenuPermission
  );
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const postStrategyFunctionDetails = useSelector(
    (state) => state.postStrategyFunctionDetails
  );
  const customFilter = (option, searchText) => {
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const getProgress = (step) => {
    if (step === 0) {
      return 0;
    } else if (step === 20) {
      return 1;
    } else if (step === 40) {
      return 2;
    } else if (step === 60) {
      return 3;
    } else if (step === 80) {
      return 4;
    } else {
      return 5;
    }
  };
  useEffect(() => {
    if (postStrategyFunctionDetails && isNewTask) {
      if (postStrategyFunctionDetails.response) {
        setNewUploadFileTaskId(postStrategyFunctionDetails.response[0].key);
        setShowNewTaskUploadFile(true);
      }
    }
    if (postStrategyFunctionDetails && isSubTaskSidebarOpen) {
      if (postStrategyFunctionDetails.response) {
        setNewUploadFileSubTaskId(postStrategyFunctionDetails.response[0].key);
        setShowSubTaskUploadFile(true);
      }
    }
  }, [postStrategyFunctionDetails]);

  useEffect(() => {
    if (
      isNewTask === false &&
      getSingleTaskDetails &&
      getSingleTaskDetails.message &&
      getSingleTaskDetails.message.length > 0
    ) {
      setCurrentTaskId(getSingleTaskDetails.message[0].id);
      setTask(getSingleTaskDetails.message);
      setMilestoneId(getSingleTaskDetails.message[0].milestone_id);
      setTaskName(getSingleTaskDetails.message[0].name);
      setDesc(getSingleTaskDetails.message[0].description);
      setAttachment(getSingleTaskDetails.message[0].attachment);
      setCurrentUser(getSingleTaskDetails.message[0].emp_id);
      setAssignedTo(
        getSingleTaskDetails.message[0].assigned_to.filter(
          (employee) => employee.is_active
        )
      );
      setStatus({
        label: getSingleTaskDetails.message[0].status,
        value: getSingleTaskDetails.message[0].status,
      });
      setPriority({
        label: getSingleTaskDetails.message[0].priority,
        value: getSingleTaskDetails.message[0].priority,
      });

      const filteredData = mileStonesData?.filter(
        (item) => item.value === getSingleTaskDetails.message[0].milestone_id
      );
      console.log('mileStonesData',mileStonesData)
      if (filteredData && filteredData[0]) {
        setMilestone({
          label: filteredData[0].label,
          value: filteredData[0].value,
        });
      } else {
        console.error(
          `No match found for id: ${getSingleTaskDetails.message[0].milestone_id}`
        );
      }
      setDuedate(getSingleTaskDetails.message[0].exp_end_date);
      setStartDate(getSingleTaskDetails.message[0].exp_start_date);
      setComments(
        getSingleTaskDetails.message[0].comments.map((item) => ({
          ...item,
          icon: createImageFromInitials(
            30,
            item.comment_by[0].name,
            getRandomColor(item.comment_by[0].name)
          ),
        }))
      );
      setSubtasks(getSingleTaskDetails.message[0].subtasks);
      setCollabarators(getSingleTaskDetails.message[0].collaberators);
      setCurrentStep(getProgress(getSingleTaskDetails.message[0].progress));
      setProgress(getSingleTaskDetails.message[0].progress);
    }
  }, [getSingleTaskDetails]);

  useEffect(() => {
    if (taskName) {
      setShowTaskUploadFile(true);
      setShowNewTaskUploadFile(false);
    }
  }, [taskName]);

  useEffect(() => {
    if (isNewTask) {
      setShowNewTaskUploadFile(false);
      setTask("");
      setTaskName("");
      setPriority("");
      setStatus("");
      setDuedate("");
      setStartDate("");
      setAssignedTo([]);
      setProgress("");
      setDesc("");
      setComments([]);
      setCollabarators([]);
      setCurrentStep("");
      setSubtasks([]);
      const filteredData = mileStonesData?.filter(
        (item) => item.value === selectedTaskMilestoneId
      );
      if (filteredData && filteredData[0]) {
        setMilestone({
          label: filteredData[0].label,
          value: filteredData[0].value,
        });
      }
    }
  }, [isNewTask]);

  useEffect(() => {
    setTask("");
    setTaskName("");
    setPriority("");
    setStatus("");
    setDuedate("");
    setStartDate("");
    setAssignedTo([]);
    setProgress("");
    setDesc("");
    setComments([]);
    setCollabarators([]);
    setCurrentStep("");
    const filteredData = mileStonesData?.filter(
      (item) => item.value === selectedTaskMilestoneId
    );
    if (filteredData && filteredData[0]) {
      setMilestone({
        label: filteredData[0].label,
        value: filteredData[0].value,
      });
    }
  }, [isSubTaskSidebarOpen]);

  const handleSubTaskDelete = () => {
    let data = [
      {
        task_id: subTaskId,
        is_active: false,
      },
    ];
    dispatch(
      postStrategyFunctions(data, "sub_task", false, false, false, true)
    );
    onClose();
  };

  const handleSubtaskNav = () => {
    setIsSubTaskSidebarOpen(true);
    setSubTaskId("");
    setIsSubTaskVisible(false);
    setIsNewSubTask(true);
  };
  const handleSubtaskSideBar = (id) => {
    setIsSubTaskSidebarOpen(true);
    setSubTaskId(id);
    dispatch(getTaskDetails(id));
  };
  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
    setProgress(stepIndex * 20);
  };

  const handleFullScreen = () => {
    setFullScreenMode(true);
  };

  const handleSideNav = () => {
    setFullScreenMode(false);
  };

  const handleOwnerChange = (e) => {
    setAssignedTo(e);
  };

  const handleStatusSelect = (selectedOptions) => {
    setStatus(selectedOptions);
  };

  const handlePrioritySelect = (selectedOptions) => {
    setPriority(selectedOptions);
  };
  const handleMilestoneSelect = (selectedOptions) => {
    console.log('setMilestone',selectedOptions)
    setMilestone(selectedOptions);
  };

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleDescOnChange = (content) => {
    setDesc(content);
  };

  const handleSave = () => {
    if (isSubTaskSidebarOpen) {
      if (isNewSubTask) {
        let assignedToList = [];
        if (Array.isArray(assignedTo)) {
          assignedToList = assignedTo;
        } else {
          assignedToList = [assignedTo];
        }
        const requiredAssignedData = empData.map((item) => ({
          key: item.value,
          is_active: assignedToList.some(
            (selectedItem) => selectedItem.value === item.value
          ),
        }));
        let data = [
          {
            bi_id: localStorage.getItem("bi_id"),
            prod_id: localStorage.getItem("product_id"),
            parent_task_id: currentTaskId,
            task_name: taskName,
            assigned_to: requiredAssignedData,
            start_date: startDate,
            due_date: duedate,
            status: status.value || "",
            priority: priority.value || "",
            milestone: milestone.value || "",
            progress: progress,
            description: desc,
            collaberators: [],
            is_active: true,
            task_id: subTaskId,
          },
        ];
        dispatch(
          postStrategyFunctions(data, "sub_task", false, false, false, true)
        );
        // if (taskName) {
        //   onClose();
        // }

      } else {
        console.log('tusdlfksdlfjsdf')
        let assignedToList = [];
        if (Array.isArray(assignedTo)) {
          assignedToList = assignedTo;
        } else {
          assignedToList = [assignedTo];
        }
        const requiredAssignedData = empData.map((item) => ({
          key: item.value,
          is_active: assignedToList.some(
            (selectedItem) => selectedItem.value === item.value
          ),
        }));
        let data = [
          {
            bi_id: localStorage.getItem("bi_id"),
            prod_id: localStorage.getItem("product_id"),
            parent_task_id: currentTaskId,
            task_name: taskName,
            assigned_to: requiredAssignedData,
            start_date: startDate,
            due_date: duedate,
            status: status.value || "",
            priority: priority.value || "",
            milestone: milestone.value || "",
            progress: progress,
            description: desc,
            collaberators: [],
            is_active: true,
            task_id: false,
          },
        ];
        dispatch(
          postStrategyFunctions(data, "sub_task", false, false, false, true)
        );
        // if (taskName) {
        //   onClose();
        // }
      }
    } else {
      let assignedToList = [];
      if (Array.isArray(assignedTo)) {
        assignedToList = assignedTo;
      } else {
        assignedToList = [assignedTo];
      }
      const requiredAssignedData = empData.map((item) => ({
        key: item.value,
        is_active: assignedToList.some(
          (selectedItem) => selectedItem.value === item.value
        ),
      }));
      let data = [
        {
          bi_id: localStorage.getItem("bi_id"),
          prod_id: localStorage.getItem("product_id"),
          parent_task_id: isNewTask ? selectedTaskMilestoneId : milestone.value,
          task_name: taskName,
          assigned_to: requiredAssignedData,
          start_date: startDate,
          due_date: duedate,
          status: status.value || "",
          priority: priority.value || "",
          milestone: milestone.value || "",
          progress: progress,
          description: desc,
          collaberators: [],
          is_active: true,
          task_id: selectedTaskId,
        },
      ];
      dispatch(postStrategyFunctions(data, "task", false, false, false, true));
      // if (taskName) {
      //   onClose();
      // }
    }
    // if (postStrategyFunctionDetails) {
    //   if (postStrategyFunctionDetails.response) {
    //     setNewTaskId(postStrategyFunctionDetails.response[0].key);
    //     setNewSubTaskId(postStrategyFunctionDetails.response[0].key);
    //   }
    // }
  };

  const logo = createImageFromInitials(
    30,
    localStorage.getItem("full_name"),
    getRandomColor(localStorage.getItem("full_name"))
  );

  const addComment = (newComment) => {
    setComments([...comments, newComment]);
  };

  const handleEditComment = (name, newContent) => {
    const updatedComments = comments.map((comment) =>
      comment.name === name ? { ...comment, content: newContent } : comment
    );
    setComments(updatedComments);
  };

  const handleDeleteComment = (name) => {
    const updatedComments = comments.filter((comment) => comment.name !== name);
    setComments(updatedComments);
  };

  return (
    <div
      style={{
        width: fullScreenMode ? "calc(100% - 50px)" : "50%",
      }}
      className={"sidenav"}
    >
      <div
        className="gtm-sidebar-header"
        style={{
          borderBottom: "1px solid lightgrey",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: "1",
            minWidth: "70%",
            maxWidth: "80%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            margin: "0",
            paddingTop: "4px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
          }}
          title={breadcrumbs}
        >
          {breadcrumbs}
        </div>
        {!fullScreenMode ? (
          <>
            {(selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin") && (
              <a href="#" className="sideMenu" onClick={handleMenuClick}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </a>
            )}
            {isMenuOpen && (
              <div className="menu">
                <div className="Edit">
                  <div
                    className="DeleteOption"
                    onClick={() =>
                      !isSubTaskSidebarOpen ? handleTaskDelete() : handleSubTaskDelete()
                    }
                  >
                    <img
                      src={DeleteIcon}
                      width={"18px"}
                      style={{ marginRight: "10px" }}
                      alt="Edit Icon"
                    />
                    Delete
                  </div>
                </div>
              </div>
            )}
            <a href="#" className="fullscreen" onClick={handleFullScreen}>
              <AiOutlineCaretRight />
            </a>
          </>
        ) : (
          <>
            {(selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin") && (
              <a href="#" className="sideMenu" onClick={handleMenuClick}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </a>
            )}
            <a href="#" className="fullscreen" onClick={handleSideNav}>
              <AiOutlineCaretLeft />
            </a>
          </>
        )}
        <a
          href="#"
          className="closebtn"
          onClick={() => {
            onClose();
          }}
        >
          &times;
        </a>
      </div>
      <div className="gtm-sidebar-body">
        <div>
          <input
            required
            type="text"
            value={taskName}
            style={{
              padding: "6px 8px",
              marginLeft: "10px",
              width: "95%",
              border: "none",
              marginBottom: "10px",
              fontSize: "var(--task-title-font-size)",
            }}
            placeholder={
              isSubTaskSidebarOpen
                ? "Write a Sub task name"
                : "Write a task name"
            }
            onChange={(e) => setTaskName(e.target.value)}
            disabled={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? false
                : true
            }
          />
        </div>
        <div className="row">
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              zIndex: 2000,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "20px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Owner
            </div>
            <Select
              required
              name="emp_id"
              // isMulti
              // hideSelectedOptions
              // allowSelectAll
              className="select-owner"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Owner
                </span>
              }
              value={assignedTo}
              options={empData}
              onChange={handleOwnerChange}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img alt="" src={e.icon} />
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
              // menuPlacement="top"
            />
          </div>
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              zIndex: 101,
            }}
          >
            <div
              style={{
                marginLeft: "20px",
                marginRight: "20px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Status
            </div>
            <Select
              required
              className="status-select"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Status
                </span>
              }
              value={status}
              options={statusData}
              onChange={handleStatusSelect}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img alt="" src={e.icon} /> */}
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "25px",
                textWrap: "nowrap",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Start Date
            </div>
            <input
              style={{
                width: "250px",
                fontSize: "var(--sub-heading-font-size)",
              }}
              type="date"
              className="form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "20px",
                marginRight: "25px",
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Due Date
            </div>
            <input
              style={{
                width: "250px",
                fontSize: "var(--sub-heading-font-size)",
              }}
              type="date"
              className="form-control due-date-select"
              value={duedate}
              onChange={(e) => setDuedate(e.target.value)}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>

          <div
            className="col-6"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              zIndex: 100,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "40px",
                marginBottom: 0,
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Priority
            </div>
            <Select
              required
              className="priority-select"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Priority
                </span>
              }
              value={priority}
              options={priorityData}
              onChange={handlePrioritySelect}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img alt="" src={e.icon} /> */}
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-12"
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
              zIndex: 3,
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "25px",
                marginBottom: 0,
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Milestone
            </div>
            <Select
              required
              className="milestone-select"
              placeholder={
                <span style={{ fontSize: "var(--text-font-size)" }}>
                  Select Milestone
                </span>
              }
              value={milestone}
              options={mileStonesData}
              onChange={handleMilestoneSelect}
              isSearchable={true}
              filterOption={customFilter}
              getOptionLabel={(e) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img alt="" src={e.icon} /> */}
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    {e.label}
                  </span>
                </div>
              )}
              isDisabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
          </div>
        </div>
        <div className="row">
          <div
            className="col-12"
            style={{
              marginBottom: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                marginRight: "20px",
                marginBottom: 0,
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Progress
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                marginLeft: "15px",
              }}
            >
              <StepProgressBar
                steps={steps}
                currentStep={currentStep}
                onStepClick={handleStepClick}
                selectedSubMenuPermission={selectedSubMenuPermission}
              />
              <div className="step-percentage">
                {(currentStep / (steps.length - 1)) * 100}%
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            marginLeft: "10px",
            marginRight: "30px",
            marginBottom: "0px",
            fontSize: "var(--sub-heading-font-size)",
            fontWeight: "var(--heading-font-weight)",
          }}
        >
          Description
        </div>
        <div style={{ margin: "10px 0px 40px 10px", height: "300px" }}>
          <QuillEditor
            value={desc}
            onChange={(content) => handleDescOnChange(content)}
            height={"300px"}
          />
        </div>

        {subtasks && subtasks.length > 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th
                    style={{
                      textAlign: "left",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    SubTasks
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {subtasks.map((subtask) => (
                  <tr>
                    <td
                      key={subtask.id}
                      style={{ textAlign: "left", paddingLeft: "10px" }}
                    >
                      {subtask.name}
                    </td>
                    <td>
                      <img
                        alt="chevronright"
                        src={ChevronRight}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleSubtaskSideBar(subtask.id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div
          style={{
            textAlign: "left",
            paddingLeft: "5px",
            paddingBottom: "10px",
            display: isNewTask ? "none" : "flex",
          }}
        >
          {isSubTaskVisible &&
            (selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin") && (
              <button
                style={{
                  display: "flex",
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  alignItems: "center",
                  marginTop: "10px",
                }}
                type="button"
                onClick={() => {
                  handleSubtaskNav();
                }}
              >
                <img
                  src={AddIcon}
                  width={16}
                  height={16}
                  style={{ marginRight: "5px" }}
                />
                Add Subtask
              </button>
            )}
        </div>
        {showTaskUploadFile && !isNewTask && !isSubTaskSidebarOpen ? (
          <>
            <div
              style={{
                fontSize: "var(--text-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Please Upload relevant documents
            </div>
            <UploadFiles
              selectedSubMenuPermission={selectedSubMenuPermission}
              taskId={selectedTaskId}
              attachments={attachment ? attachment : ""}
            />
          </>
        ) : showNewTaskUploadFile && isNewTask ? (
          <>
            <div
              style={{
                fontSize: "var(--text-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Please Upload relevant documents
            </div>
            <UploadFiles
              selectedSubMenuPermission={selectedSubMenuPermission}
              taskId={newUploadFileTaskId}
            />
          </>
        ) : isSubTaskSidebarOpen && !showSubTaskUploadFile && !isNewSubTask ? (
          <>
            <div
              style={{
                fontSize: "var(--text-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Please Upload relevant documents
            </div>
            <UploadFiles
              selectedSubMenuPermission={selectedSubMenuPermission}
              taskId={subTaskId}
              attachments={attachment ? attachment : ""}
            />
          </>
        ) : isSubTaskSidebarOpen && showSubTaskUploadFile && isNewSubTask ? (
          <>
            <div
              style={{
                fontSize: "var(--text-font-size)",
                fontWeight: "var(--heading-font-weight)",
                paddingLeft: "10px",
              }}
            >
              Please Upload relevant documents
            </div>
            <UploadFiles
              selectedSubMenuPermission={selectedSubMenuPermission}
              taskId={newUploadFieSubTaskId}
            />
          </>
        ) : null}

        {isNewTask === false && (
          <div>
            <CommentSection
              currentUser={currentUser}
              logo={logo}
              fullScreenMode={fullScreenMode}
              taskId={selectedTaskId}
              commentsData={comments}
              onAddComment={addComment}
              onEditComment={handleEditComment}
              onDeleteComment={handleDeleteComment}
            />
          </div>
        )}
      </div>
      <div>
        <Button
          type="button"
          style={{
            position: "absolute",
            top: "8px",
            right: "150px",
            padding: "4px 10px",
            backgroundColor: "var(--button-primary-color)",
            fontSize: "var(--sub-heading-font-size)",
            border: "1px solid var(--button-primary-color)",
          }}
          onClick={() => handleSave()}
          disabled={
            selectedSubMenuPermission === "write" ||
            selectedSubMenuPermission === "admin"
              ? false
              : true
          }
        >
          Save
        </Button>
      </div>
    </div>
  );
}

const UploadFiles = ({
  selectedSubMenuPermission,
  attachments,
  taskId,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState([]);

  const fetchpostLocalManagerData = useSelector(
    (state) => state.fetchpostLocalManagerData
  );
  const fetchpostLocalFolderManagerFileDelete = useSelector(
    (state) => state.fetchpostLocalFolderManagerFileDelete
  );

  useEffect(() => {
    if (fetchpostLocalManagerData && fetchpostLocalManagerData.status === 200) {
      if (fetchpostLocalManagerData.message) {
        const attachedToNames = fetchpostLocalManagerData.message.map(
          (item) => item.attached_to_name
        );
        if (attachedToNames.includes(taskId)) {
          setFiles((prevFiles) => [
            ...prevFiles,
            ...fetchpostLocalManagerData.message,
          ]);
        }
      }
    }
  }, [fetchpostLocalManagerData]);
  useEffect(() => {
    if (attachments && taskId) {
      setFiles(attachments);
    } else {
      setFiles("");
    }
  }, [attachments]);
  useEffect(() => {
    if (
      fetchpostLocalFolderManagerFileDelete &&
      fetchpostLocalFolderManagerFileDelete.status === 200
    ) {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles?.filter(
          (existingItem) =>
            !fetchpostLocalFolderManagerFileDelete.file_id.includes(
              existingItem.name
            )
        );
        return updatedFiles;
      });
    }
  }, [fetchpostLocalFolderManagerFileDelete]);

  const handleDeleteFile = (id) => {
    Alert.warning({
      title: "Warning",
      // html: ISError,
      text: "Are you sure do you want to delete the File?",
      icon: "warning",
      showCancelButton: true,
      preConfirm: (isConfirm) => {
        if (isConfirm) {
          dispatch(postLocalFolderManagerFileDelete(id));
          dispatch(resetExceptionError());
        }
      },
    });
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };
  const [isMSDocsSend, setIsMSDocsSend] = useState(false);

  function handleFile(files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      setIsMSDocsSend(!isMSDocsSend);
      if (taskId) {
        dispatch(
          postLocalFolderManager(
            localStorage.getItem("bi_id"),
            localStorage.getItem("screen_id"),
            localStorage.getItem("main_screen_id"),
            file,
            "",
            "",
            localStorage.getItem("product_id"),
            taskId
          )
        );
      }
    }
  }
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  return (
    <div>
      <div className="row">
        <div>
          <form
            id="form-file-upload"
            onDragEnter={
              selectedSubMenuPermission === "write" ||
              selectedSubMenuPermission === "admin"
                ? handleDrag
                : null
            }
            onSubmit={(e) => e.preventDefault()}
            style={{
              height: "60px",
              width: "80%",
              margin: "auto",
              marginBottom: "20px",
              marginTop: "10px",
            }}
          >
            <input
              ref={inputRef}
              type="file"
              id="input-file-upload"
              multiple={true}
              onChange={handleChange}
              disabled={
                selectedSubMenuPermission === "write" ||
                selectedSubMenuPermission === "admin"
                  ? false
                  : true
              }
            />
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? "drag-active" : ""}
            >
              <div>
                <button
                  className="upload-button"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    onButtonClick();
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor:
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? "pointer"
                        : "default",
                    textDecoration:
                      selectedSubMenuPermission === "write" ||
                      selectedSubMenuPermission === "admin"
                        ? ""
                        : "none",
                  }}
                >
                  <div>
                    <img src={UploadImage} />
                  </div>
                  <span
                    style={{
                      color: "#0DA14B",
                      marginLeft: "5px",
                      fontSize: "var(--sub-heading-font-size)",
                      fontWeight: "var(--heading-font-weight)",
                    }}
                  >
                    Upload a file
                  </span>
                  <span
                    style={{
                      paddingLeft: "10px",
                      fontSize: "var(--sub-heading-font-size)",
                    }}
                  >
                    or drag and drop a file
                  </span>
                </button>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
          <div>
            {files.length > 0 ? (
              <>
                <table className="bordered-table-upload-files">
                  <thead
                    style={{
                      backgroundColor: "#F6F8FA",
                    }}
                  >
                    <tr>
                      <th>File</th>
                      <th>Modified</th>
                      <th>Modified By</th>
                      {selectedSubMenuPermission === "admin" && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {files.length > 0 &&
                      files.map((obj) => (
                        <tr key={obj.name}>
                          <td style={{ textAlign: "left" }}>
                            <a
                              href={obj.file_url}
                              target="_blank"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                              }}
                            >
                              <img
                                src={
                                  obj.img
                                    ? url + obj.img
                                    : obj.file_name.split(".").pop() ===
                                        "xls" ||
                                      obj.file_name.split(".").pop() ===
                                        "xlsx" ||
                                      obj.file_name.split(".").pop() === "csv"
                                    ? SheetImage
                                    : obj.file_name.split(".").pop() === "pdf"
                                    ? PDFImage
                                    : obj.file_name.split(".").pop() ===
                                        "doc" ||
                                      obj.file_name.split(".").pop() === "docx"
                                    ? DocImage
                                    : obj.file_name.split(".").pop() === "ppt"
                                    ? PPTImage
                                    : OtherImage
                                }
                                style={{
                                  width: 25,
                                  height: 25,
                                }}
                              />
                              <label
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  fontSize: "var(--text-font-size)",
                                  width: "300px",
                                }}
                              >
                                {obj.file_name}
                              </label>
                            </a>
                          </td>
                          <td style={{ fontSize: "var(--text-font-size)" }}>
                            {obj.modified}
                          </td>
                          <td style={{ fontSize: "var(--text-font-size)" }}>
                            {obj.modified_by}
                          </td>
                          {selectedSubMenuPermission === "admin" && (
                            <td>
                              <span
                                className="folder-managementicons"
                                title="Delete"
                                onClick={() => handleDeleteFile(obj.name)}
                              >
                                <img src={DeleteIcon} width={14} />
                              </span>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <label
                  style={{
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                    marginLeft: "10px",
                  }}
                >
                  All Documents
                </label>
                <div style={{ textAlign: "center" }}>
                  <img src={NoDocImage} />
                  <div>
                    <label
                      style={{
                        fontSize: "var(--sub-heading-font-size)",
                        fontWeight: "var(--heading-font-weight)",
                      }}
                    >
                      No Documents
                    </label>
                  </div>
                  <div>
                    <label
                      style={{
                        fontSize: "var(--sub-heading-font-size)",
                      }}
                    >
                      Uploaded documents will appear here
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskSidebar;
