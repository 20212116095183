import { useState, useReducer, useEffect } from "react";
import "./onboarding/vcmapping/Mapping.css";
import { BiSave } from "react-icons/bi";
import { PiDotsSixVerticalLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerVCSelection } from "../store/actions";
import { Button } from "react-bootstrap";
import EditDark from "../assets/images/Edit.svg";
import Cancel from "../assets/images/Cancel.svg";
import AddIcon from "../assets/images/add.svg";

const VCEditModal = (props) => {
  const dispatch = useDispatch();
  const { valueChain } = props;
  const [finalValueChain, setFinalValueChain] = useState(valueChain);
  const [selectedMenu, setSelectedMenu] = useState(valueChain.activeMenu);
  const [categories, setCategories] = useState(valueChain.categories);
  const [dimensionField, setDimensionField] = useState("");
  const [showDimensionField, setShowDimensionField] = useState(false);
  const [inputField, setInputField] = useState("");
  const [showInputField, setShowInputField] = useState(false);
  const [editValueChain, setEditValueChain] = useState(false);
  const [targetCategoryIndex, setTargetCategoryIndex] = useState(0);
  const [finalCategory, setFinalCategory] = useState(categories);
  const [vcNameAlreadyExists, setVCNameAlreadyExists] = useState(false);
  const [valueChainValue, setValueChainValue] = useState(
    props.valueChain.value
  );
  const getCustomerVCSelectionData = useSelector(
    (state) => state.getCustomerVCSelectionData
  );
  var updatedColumnsCheck = props.updatedColumnsCheck;

  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (index, e) => {
    e.preventDefault();
  };

  const handleDragEnter = (index) => {
    if (draggedIndex === null) return;

    const data = [...categories];
    const draggedItem = data[draggedIndex];

    data.splice(draggedIndex, 1);
    data.splice(index, 0, draggedItem);
    setDraggedIndex(index);

    const updatedData = data.map((item, index) => ({
      ...item,
      placing: index + 1, // Assuming the index should start from 1
    }));
    setCategories(updatedData);
    setFinalCategory(updatedData);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  const getDraggedStyle = (index) => {
    return draggedIndex === index ? { backgroundColor: "#e0e0e0" } : {};
  };

  const [dimensiondraggedIndex, setDimensionDraggedIndex] = useState(null);

  const handleDimDragStart = (index) => {
    setDimensionDraggedIndex(index);
  };

  const handleDimDragOver = (index, e) => {
    e.preventDefault();
  };
  const handleDimDragEnter = (dimindex) => {
    if (dimensiondraggedIndex === null) return;
    const data = selectedMenu.columns;
    const draggedItem = data[dimensiondraggedIndex];
    data.splice(dimensiondraggedIndex, 1);
    data.splice(dimindex, 0, draggedItem);
    setDimensionDraggedIndex(dimindex);

    const updatedData = {
      ...selectedMenu,
      columns: selectedMenu.columns.map((item, index) => ({
        ...item,
        placing: index + 1,
      })),
    };
    setSelectedMenu(updatedData);
    const updatedCategories = [...categories];
    updatedCategories[targetCategoryIndex] = updatedData;
    const updatedValueChain = {
      activeMenu: updatedData,
      categories: updatedCategories,
      data: valueChain.data,
      is_master: valueChain.is_master,
      isactive: valueChain.isactive,
      key: valueChain.key,
      showEditModal: valueChain.showEditModal,
      value: valueChain.value,
    };
    setFinalCategory(updatedCategories);
    setFinalValueChain(updatedValueChain);
  };

  const handleDimDragEnd = () => {
    setDimensionDraggedIndex(null);
  };

  const getDimDraggedStyle = (index) => {
    return dimensiondraggedIndex === index
      ? { backgroundColor: "#e0e0e0" }
      : {};
  };

  const handleInputChange = (event) => {
    setInputField(event.target.value);
  };
  const handleAddDimensionFields = () => {
    setDimensionField(null);
    setShowDimensionField(true);
  };
  const handleAddFields = () => {
    setInputField(null);
    setShowInputField(true);
  };
  const handleInputDimensionChange = (event) => {
    setDimensionField(event.target.value);
  };
  const onMenuChange = (menuKey, menuindex) => {
    setTargetCategoryIndex(menuindex);
    let selected = categories.filter((e) => e.key == menuKey)[0];
    setSelectedMenu(selected);
  };
  const handleSubmit = (inputField) => {
    if (inputField != "" && inputField) {
      const category = [...categories];
      category.push({
        key: "category" + category.length,
        value: inputField,
        isactive: true,
        iscustom: true,
        columns: [{ key: "id", value: "", isactive: true }],
        placing: category.length + 1,
        data: props.products,
      });
      // setCategories((state) => {
      //   let categories = [...state];
      //   categories.push({
      //     key: "category" + categories.length,
      //     value: inputField,
      //     isactive: true,
      //     iscustom: true,
      //     columns: [{ key: "id", value: "", isactive: true }],
      //     placing: categories.placing,
      //     data: props.products,
      //   });
      //   return categories;
      // });
      setCategories(category);
      setFinalCategory(category);
    }

    setShowInputField(false);
  };
  // useEffect(()=>{
  //   let c = [...categories];
  //   let selected = {
  //     ...c.filter((e) => e.key == selectedMenu.key)[0],
  //   };
  //   console.log('selected',selectedMenu.key)
  //   console.log('c',c)
  // })
  const handleDimensionSubmit = (dimensionField) => {
    if (dimensionField != "" && dimensionField) {
      let c = [...categories];
      let selected = {
        ...categories.filter((e) => e.key == selectedMenu.key)[0],
      };
      let updateColumns = [...selected.columns];
      const placingOfLast = updateColumns[updateColumns.length - 1].placing;
      updateColumns.push({
        key: "dimension" + selected.columns.length,
        value: dimensionField,
        placing: placingOfLast + 1,
        isactive: true,
        iscustom: true,
        children: [],
      });
      let updatedMenu = { ...selected, columns: updateColumns };

      let newCategories = categories.map((m) =>
        m.key === updatedMenu.key ? updatedMenu : m
      );
      setCategories(newCategories);
      setFinalCategory(newCategories);
      setSelectedMenu(updatedMenu);
    }
    setShowDimensionField(false);
  };

  function findParent(items, key, type, value) {
    items = items.map((c) => {
      if (c.key === key) {
        if (type === "selection") {
          c = { ...c, isactive: !c.isactive };
        } else if (type === "updation") {
          c = { ...c, value: value };
        } else if (type === "iseditable") {
          c = { ...c, isEdit: true };
        } else if (type === "iseditsave") {
          c = { ...c, isEdit: false };
        } else if (type === "isadd") {
          c = { ...c, isAdd: true };
        } else if (type === "isaddsave") {
          let updatedChildren = [...c.children];
          if (c.addedvalue != "" && c.addedvalue) {
            updatedChildren.push({
              key: "subdimension" + c.key + c.children.length,
              value: c.addedvalue,
              isactive: true,
              iscustom: true,
              children: [],
            });
          }
          c = { ...c, isAdd: false, children: updatedChildren, addedvalue: "" };
        } else if (type === "isaddsubdimension") {
          c = { ...c, addedvalue: value };
        }
        return c;
      } else if (c.children) {
        c.children = findParent(c.children, key, type, value);
      } else {
      }
      return c;
    });
    return items;
  }

  const handleCheckboxChange = (key, dimactive) => {
    let selected = categories.filter((e) => e.key == selectedMenu.key)[0];
    let activeDimensionsLength = selected.columns.filter(
      (c) => c.isactive === true
    ).length;
    if (activeDimensionsLength <= 2 && !dimactive === false) {
      return;
    }
    const updatedColumns = findParent(selected.columns, key, "selection");

    let updatedMenu = { ...selected, columns: updatedColumns };
    let newCategories = categories.map((m) =>
      m.key === updatedMenu.key ? updatedMenu : m
    );
    setCategories(newCategories);
    setSelectedMenu(updatedMenu);
    setFinalCategory(newCategories);
  };

  const handleMenuCheckboxChange = (key, menuactive) => {
    let activeCategoriesLength = categories.filter(
      (c) => c.isactive === true
    ).length;
    if (activeCategoriesLength <= 1 && !menuactive === false) {
      return;
    }
    let updatedCategories = categories.map((c) => {
      return c.key === key ? { ...c, isactive: !c.isactive } : c;
    });
    setCategories(updatedCategories);
    setFinalCategory(updatedCategories);
  };

  const handleEditDimension = (key) => {
    let selected = categories.filter((e) => e.key == selectedMenu.key)[0];

    const updatedColumns = findParent(selected.columns, key, "iseditable");

    let updatedMenu = { ...selected, columns: updatedColumns };
    let newCategories = categories.map((m) =>
      m.key === updatedMenu.key ? updatedMenu : m
    );
    setCategories(newCategories);
    setSelectedMenu(updatedMenu);
  };
  const handleSaveDimension = (key) => {
    let selected = categories.filter((e) => e.key == selectedMenu.key)[0];

    const updatedColumns = findParent(selected.columns, key, "iseditsave");
    let updatedMenu = { ...selected, columns: updatedColumns };
    let newCategories = categories.map((m) =>
      m.key === updatedMenu.key ? updatedMenu : m
    );
    setCategories(newCategories);
    setSelectedMenu(updatedMenu);
    setFinalCategory(newCategories);
  };

  const handleExpandSubListFields = (key) => {
    let selected = {
      ...categories.filter((e) => e.key == selectedMenu.key)[0],
    };
    const updatedColumns = findParent(selected.columns, key, "isadd");
    let updatedMenu = { ...selected, columns: updatedColumns };
    let newCategories = categories.map((m) =>
      m.key === updatedMenu.key ? updatedMenu : m
    );
    setCategories(newCategories);
    setSelectedMenu(updatedMenu);
  };
  const handleSaveSubFields = (key) => {
    let selected = categories.filter((e) => e.key == selectedMenu.key)[0];
    const updatedColumns = findParent(selected.columns, key, "isaddsave");
    const orderedColumns = updatedColumns.map((column, index) => ({
      ...column,
      placing_order: index + 1, // Start ordering from 1
    }));
    let updatedMenu = { ...selected, columns: orderedColumns };

    let newCategories = categories.map((m) =>
      m.key === updatedMenu.key ? updatedMenu : m
    );
    setCategories(newCategories);
    setSelectedMenu(updatedMenu);
    setFinalCategory(newCategories);
  };

  const handleSubDimensionAdd = (e, key) => {
    let selected = {
      ...categories.filter((e) => e.key == selectedMenu.key)[0],
    };

    const updatedColumns = findParent(
      selected.columns,
      key,
      "isaddsubdimension",
      e.target.value
    );
    let updatedMenu = { ...selected, columns: updatedColumns };
    let newCategories = categories.map((m) =>
      m.key === updatedMenu.key ? updatedMenu : m
    );
    setCategories(newCategories);
    setSelectedMenu(updatedMenu);
  };

  const handleDimensionChange = (e, key) => {
    let selected = categories.filter((e) => e.key == selectedMenu.key)[0];

    const updatedColumns = findParent(
      selected.columns,
      key,
      "updation",
      e.target.value
    );
    let updatedMenu = { ...selected, columns: updatedColumns };
    let newCategories = categories.map((m) =>
      m.key === updatedMenu.key ? updatedMenu : m
    );
    setCategories(newCategories);
    setSelectedMenu(updatedMenu);
  };

  const handleEditCategory = (key) => {
    let updatedCategories = categories.map((c) => {
      return c.key === key ? { ...c, isEdit: true } : c;
    });
    setCategories(updatedCategories);
  };
  const handleSaveCategory = (key) => {
    let updatedCategories = categories.map((c) => {
      return c.key === key ? { ...c, isEdit: false } : c;
    });
    setCategories(updatedCategories);
    setFinalCategory(updatedCategories);
  };

  const handleCategoryChange = (e, key) => {
    let updatedCategories = categories.map((c) => {
      return c.key === key ? { ...c, value: e.target.value } : c;
    });
    setCategories(updatedCategories);
  };

  const handleEditVCName = () => {
    setEditValueChain(true);
  };
  const handleSaveVCName = (e) => {
    console.log(valueChainValue);
    dispatch(
      getCustomerVCSelection(
        localStorage.getItem("bi_id"),
        valueChainValue,
        "",
        "",
        "",
        true
      )
    );
  };

  const handleVCNameChange = (e) => {
    setValueChainValue(e.target.value);
  };

  useEffect(() => {
    if (getCustomerVCSelectionData?.data?.status) {
      if (getCustomerVCSelectionData?.data?.status === 400) {
        setVCNameAlreadyExists(true);
      } else if (getCustomerVCSelectionData?.data?.status === 200) {
        setVCNameAlreadyExists(false);
        setEditValueChain(false);
      }
    }
  }, [getCustomerVCSelectionData]);
  return (
    <div>
      <div style={{ marginLeft: "15px" }}>
        {editValueChain ? (
          <>
            <div style={{ display: "flex" }}>
              <input
                style={{
                  borderRadius: 10,
                  paddingLeft: 10,
                  width: 200,
                }}
                type="text"
                value={valueChainValue}
                onChange={(e) => handleVCNameChange(e)}
                required
              />

              <button
                type="button"
                style={{
                  marginLeft: 10,
                  marginTop: 8,
                  background: "white",
                  borderColor: "white",
                  height: 27,
                  width: 27,
                  border: "thin ridge",
                  borderRadius: 8,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  paddingBottom: 4,
                  color: "rgb(10, 95, 89)",
                }}
                onClick={(e) => handleSaveVCName(e)}
              >
                <span>
                  <BiSave />
                </span>
              </button>
            </div>
            <div>
              {vcNameAlreadyExists && (
                <div
                  style={{
                    color: "var(--required-color)",
                    fontWeight: "500",
                  }}
                >
                  *This value-chain name already exists, please enter a unique
                  name for the value chain.
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  paddingLeft: "10px",
                }}
              >
                <div
                  style={{
                    fontSize: "var(--sub-heading-font-size)",
                    fontWeight: "var(--heading-font-weight)",
                  }}
                >
                  {valueChainValue}
                </div>
              </span>

              <button
                style={{
                  margin: "5px",
                  backgroundColor: "transparent",
                  border: "none",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="button"
                onClick={(e) => handleEditVCName()}
              >
                <img src={EditDark} />
              </button>
            </div>
          </>
        )}
        <div
          className="column-selection-popup"
          style={{ display: "flex", height: 550, marginTop: 10 }}
        >
          <div
            style={{
              width: "50%",
              background: "#ecf4f3",
              overflow: "auto",
            }}
          >
            <ul>
              <div
                style={{
                  padding: "10px 0px 0px 20px",
                  fontSize: "var(--sub-heading-font-size)",
                  fontWeight: "var(--heading-font-weight)",
                }}
              >
                Category
              </div>
              {categories.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => onMenuChange(menu.key, index)}
                  className={
                    selectedMenu && selectedMenu.key === menu.key
                      ? "modalmenus active"
                      : "modalmenus"
                  }
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between ",
                    ...getDraggedStyle(index),
                    cursor: "grab",
                  }}
                  draggable
                  onDragStart={() => handleDragStart(index)}
                  onDragOver={(e) => handleDragOver(index, e)}
                  onDragEnter={() => handleDragEnter(index)}
                  onDragEnd={handleDragEnd}
                >
                  {menu.isEdit ? (
                    <>
                      <input
                        style={{
                          borderRadius: 10,
                          paddingLeft: 10,
                          fontSize: "var(--text-font-size)",
                        }}
                        type="text"
                        value={menu.value}
                        onChange={(e) => handleCategoryChange(e, menu.key)}
                      />
                      <button
                        type="button"
                        style={{
                          marginLeft: 10,
                          background: "white",
                          borderColor: "white",
                          height: 27,
                          width: 27,
                          border: "thin ridge",
                          borderRadius: 8,
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          paddingBottom: 4,
                          color: "rgb(10, 95, 89)",
                        }}
                        onClick={() => handleSaveCategory(menu.key)}
                      >
                        <span>
                          <BiSave />
                        </span>
                      </button>
                    </>
                  ) : (
                    <>
                      <div>
                        <PiDotsSixVerticalLight />
                      </div>
                      <input
                        className="vccheckbox"
                        type="checkbox"
                        checked={menu.isactive}
                        onChange={() =>
                          handleMenuCheckboxChange(menu.key, menu.isactive)
                        }
                        style={{ marginRight: 10 }}
                      />
                      <label style={{ fontSize: "var(--text-font-size)" }}>
                        {menu.value}
                      </label>
                      <button
                        style={{
                          marginLeft: 10,
                          marginRight: 5,
                          height: 17,
                          width: 17,
                          border: "none",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "transparent",
                          display: "flex",
                          marginTop: 3,
                          marginInlineStart: "auto",
                          marginBlock: "auto",
                        }}
                        type="button"
                        onClick={(e) => handleEditCategory(menu.key)}
                      >
                        <span style={{ paddingBottom: 2 }}>
                          <img src={EditDark} />
                        </span>
                      </button>
                    </>
                  )}
                </li>
              ))}
            </ul>
            <div>
              {!showInputField && (
                <button
                  style={{
                    marginLeft: 20,
                    height: 20,
                    width: 20,
                    borderRadius: 20,
                    border: "none",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    paddingBottom: 3,
                    backgroundColor: "transparent",
                  }}
                  type="button"
                  onClick={handleAddFields}
                >
                  <img src={AddIcon} />
                </button>
              )}
              {showInputField && (
                <div style={{ marginLeft: 20, display: "flex" }}>
                  <input
                    style={{ borderRadius: 10, paddingLeft: 10 }}
                    type="text"
                    value={inputField}
                    onChange={handleInputChange}
                  />
                  <button
                    style={{
                      marginLeft: 10,
                      marginTop: 8,
                      background: "white",
                      borderColor: "white",
                      height: 27,
                      width: 27,
                      border: "thin ridge",
                      borderRadius: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      paddingBottom: 4,
                      color: "rgb(10, 95, 89)",
                    }}
                    type="button"
                    onClick={() => handleSubmit(inputField)}
                  >
                    <span>
                      <BiSave />
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginLeft: 20,
              overflow: "auto",
              width: "50%",
            }}
          >
            <div
              style={{
                fontSize: "var(--sub-heading-font-size)",
                fontWeight: "var(--heading-font-weight)",
              }}
            >
              Dimension
            </div>
            {selectedMenu &&
              selectedMenu.columns &&
              selectedMenu.columns.map((column, index) => (
                <div style={{ marginRight: 10 }}>
                  <SubItems
                    selectedMenu={selectedMenu}
                    setSelectedMenu={(value) => setSelectedMenu(value)}
                    key={column.key}
                    column={column}
                    index={index}
                    handleDimensionChange={handleDimensionChange}
                    handleSaveDimension={handleSaveDimension}
                    handleCheckboxChange={handleCheckboxChange}
                    handleEditDimension={handleEditDimension}
                    handleExpandSubListFields={handleExpandSubListFields}
                    handleSaveSubFields={handleSaveSubFields}
                    handleSubDimensionAdd={handleSubDimensionAdd}
                    handleDimDragStart={(value) => handleDimDragStart(value)}
                    handleDimDragOver={(value, e) =>
                      handleDimDragOver(value, e)
                    }
                    handleDimDragEnter={(value) => handleDimDragEnter(value)}
                    handleDimDragEnd={handleDimDragEnd}
                    getDimDraggedStyle={(value) => getDimDraggedStyle(value)}
                  />
                </div>
              ))}
            <div>
              {!showDimensionField && (
                <button
                  style={{
                    height: 20,
                    width: 20,
                    borderRadius: 20,
                    border: "none",
                    backgroundColor: "transparent",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    paddingBottom: 3,
                    marginTop: 10,
                  }}
                  type="button"
                  onClick={handleAddDimensionFields}
                >
                  <img src={AddIcon} />
                </button>
              )}
              {showDimensionField && (
                <div style={{ marginLeft: 10, marginTop: 10, display: "flex" }}>
                  <input
                    style={{ borderRadius: 10, paddingLeft: 10 }}
                    type="text"
                    value={dimensionField}
                    onChange={handleInputDimensionChange}
                  />
                  <button
                    type="button"
                    style={{
                      marginLeft: 10,
                      marginTop: 10,
                      background: "white",
                      borderColor: "white",
                      height: 27,
                      width: 27,
                      border: "thin ridge",
                      borderRadius: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      paddingBottom: 4,
                      color: "rgb(10, 95, 89)",
                    }}
                    onClick={() => handleDimensionSubmit(dimensionField)}
                  >
                    <span>
                      <BiSave />
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Button
          type="button"
          className="save-button-style-rhpo"
          onClick={() =>
            props.onSave(
              {
                ...finalValueChain,
                value: valueChainValue,
                categories: finalCategory,
                activeMenu: selectedMenu,
              },
              updatedColumnsCheck
            )
          }
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const SubItems = ({
  setSelectedMenu,
  selectedMenu,
  column,
  index,
  handleDimensionChange,
  handleSaveDimension,
  handleCheckboxChange,
  handleEditDimension,
  handleExpandSubListFields,
  handleSaveSubFields,
  issubdimension,
  handleSubDimensionAdd,
  handleDimDragStart,
  handleDimDragOver,
  handleDimDragEnter,
  handleDimDragEnd,
  getDimDraggedStyle,
}) => {
  return (
    <div key={column.key} style={{ marginTop: "10px" }}>
      {column.key === "id" ? null : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            // ...getDimDraggedStyle(index),
            cursor: "grab",
          }}
          draggable
          onDragStart={() => handleDimDragStart(index)}
          onDragOver={(e) => handleDimDragOver(index, e)}
          onDragEnter={() => handleDimDragEnter(index)}
          onDragEnd={handleDimDragEnd}
        >
          {column.isEdit ? (
            <>
              <input
                style={{
                  borderRadius: 10,
                  paddingLeft: 10,
                  fontSize: "var(--text-font-size)",
                }}
                type="text"
                value={column.value}
                onChange={(e) => handleDimensionChange(e, column.key)}
              />
              <button
                type="button"
                style={{
                  marginLeft: 10,
                  background: "white",
                  borderColor: "white",
                  height: 20,
                  width: 20,
                  border: "thin ridge",
                  borderRadius: 20,
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  marginInlineStart: "auto",
                  paddingBottom: 3,
                }}
                onClick={() => handleSaveDimension(column.key)}
              >
                <span>
                  <BiSave />
                </span>
              </button>
            </>
          ) : (
            <>
              <div>
                <PiDotsSixVerticalLight />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <label
                  style={{
                    marginRight: 10,
                    fontSize: "var(--text-font-size)",
                  }}
                >
                  <input
                    className="vccheckbox"
                    type="checkbox"
                    checked={column.isactive}
                    onChange={() =>
                      handleCheckboxChange(column.key, column.isactive)
                    }
                    style={{ marginRight: 10 }}
                  />
                  {column.value}
                </label>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <button
                    style={{
                      background: "white",
                      height: 17,
                      width: 17,
                      border: "none",
                      backgroundColor: "transparent",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 3,
                      marginInlineStart: "auto",
                      marginBlock: "auto",
                    }}
                    type="button"
                    onClick={(e) =>
                      handleEditDimension(column.key, column.value)
                    }
                    // onClick={(e) => onEdit(vc)}
                  >
                    <span style={{ paddingBottom: 2 }}>
                      <img src={EditDark} />
                    </span>
                  </button>
                  {!column.isAdd && !issubdimension ? (
                    <button
                      style={{
                        marginLeft: 20,
                        height: 20,
                        width: 20,
                        borderRadius: 20,
                        border: "none",
                        backgroundColor: "transparent",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                      key={column.key}
                      type="button"
                      onClick={() => handleExpandSubListFields(column.key)}
                    >
                      <img src={AddIcon} />
                    </button>
                  ) : (
                    <div style={{ width: "40px" }}></div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {column.isAdd && (
        <div style={{ display: "flex" }}>
          <input
            style={{ borderRadius: 10, paddingLeft: 10 }}
            type="text"
            value={column.addedvalue}
            onChange={(e) => handleSubDimensionAdd(e, column.key)}
          />
          <button
            type="button"
            style={{
              marginLeft: 10,
              marginTop: 10,
              background: "white",
              borderColor: "white",
              height: 20,
              width: 20,
              border: "thin ridge",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              paddingBottom: 3,
            }}
            onClick={() => {
              handleSaveSubFields(column.key);
            }}
          >
            <span>
              <BiSave />
            </span>
          </button>
        </div>
      )}

      <div style={{ marginLeft: "15px" }}>
        {column.children &&
          column.children.map((column) => (
            <SubItems
              selectedMenu={selectedMenu}
              setSelectedMenu={(value) => setSelectedMenu(value)}
              key={column.key}
              column={column}
              index={index}
              issubdimension={true}
              handleDimensionChange={handleDimensionChange}
              handleSaveDimension={handleSaveDimension}
              handleCheckboxChange={handleCheckboxChange}
              handleEditDimension={handleEditDimension}
              handleExpandSubListFields={handleExpandSubListFields}
              handleSaveSubFields={handleSaveSubFields} 
              handleSubDimensionAdd={handleSubDimensionAdd}
              handleDimDragStart={(value) => handleDimDragStart(value)}
              handleDimDragOver={(value, e) => handleDimDragOver(value, e)}
              handleDimDragEnter={(value) => handleDimDragEnter(value)}
              handleDimDragEnd={handleDimDragEnd}
              getDimDraggedStyle={(value) => getDimDraggedStyle(value)}
            />
          ))}
      </div>
    </div>
  );
};

export default VCEditModal;
